require('./bootstrap');
const window = require("inputmask/lib/global/window");

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function () {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger me-2'
        },
        buttonsStyling: false,
    })

    // record delete js
    $(document).on("click", ".record-delete", function (e) {
        e.preventDefault();
        var url = $(this).attr('href');

        swalWithBootstrapButtons.fire({
            title: 'Are you sure? Do you want to delete?',
            text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonClass: 'me-2',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                location.href = url;
            }
        })
    });

    // check article number
    $("#article_number").on('change', function () {
        $.ajax({
            url: $(this).attr('data-url') + "/" + $(this).val(), // or '/api/data' for API route
            type: 'GET',
            success: function (response) {
                if (response == 1) {
                    $("#order_request_submit").attr('disabled', true)
                    $(".text-danger").text("Article number is exist!");
                } else {
                    $("#order_request_submit").attr('disabled', false);
                    $(".text-danger").text("");
                }
            }
        });
    });

    // product collection add
    $('#product_collection_item_add_btn').click(function () {
        var clonedProduct = $('#product_collection .product_collection_item').first().clone();
        clonedProduct.find('input[type="text"]').val("");
        clonedProduct.find('input[type="number"]').val("");
        $('#product_collection').append(clonedProduct);
    });

    $(document).on('click', '.delete_product_item_collection', function () {
        let product_collection_len = $("#product_collection .product_collection_item").length;
        if (product_collection_len > 1) {
            $(this).parents(".product_collection_item").remove();
        }else{
            swalWithBootstrapButtons.fire({
                title: '',
                text: "You cannot make the products empty!",
                icon: 'question',
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'OK',
                reverseButtons: true
            })
        }
    });
});
